import React, { useEffect, useState } from 'react'
import { Box, Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useStoreActions, useStoreState } from '../../store/store.hooks'
//import { useForm } from 'react-hook-form'
import { Heading } from '../../components/Text'
import { NavigateBack } from '../../components/Nav/NavigateBack'
import { GradientButton } from '../../components/Button'
import { useRelativeNavigate } from '../../hooks/useRelativeNavigate'
import { get, deleteReq } from '../../services/request'
import { CheckCircleOutline as CheckCircleOutlineIcon } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  evaluationContainer: {
    margin: '1rem 0',
  },

  completedBox: {
    textAlign: 'center',
  },

  completedText: {
    color: '#1E2864',
    fontWeight: 'bold',
  },

  headerTypography: {
    textTransform: 'uppercase',
  },

  intro: {
    margin: '0 2rem 2rem',
  },

  profileName: {
    fontSize: '2rem',
    color: '#1E2864',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  profileNameContainer: {
    margin: '1rem',
  },

  root: {},

  shortIntroText: {
    fontSize: '1.1rem',
    textAlign: 'center',
  },

  surveyCard: {
    padding: '1rem 1rem',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  surveyCards: {
    margin: 0,
    justifyContent: 'center',
  },

  surveyDescription: {
    fontSize: '0.9rem',
    textAlign: 'center',
  },

  surveyName: {
    color: '#1E2864',
    fontWeight: 'bold',
    fontSize: '1.1rem',
    textAlign: 'center',
  },

  startButton: {
    marginTop: '2rem',
  },

  wellnessSuiteContainer: {
    paddingTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '15px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
}))

const WellnessSuite = (
  {
    /* props */
  }
) => {
  const classes = useStyles()
  const {
    t,
    i18n: { language },
  } = useTranslation('survey')
  const {
    profile: { profileContents },
  } = useStoreState((state) => state)
  const { relativeNavigate } = useRelativeNavigate()
  const [surveys, setSurveys] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const hasSurveys = surveys && surveys.length > 0
  const allSurveysCompleted = hasSurveys && surveys.every((s) => s.completed)

  const fetchSurveys = async () => {
    try {
      setLoading(true)
      const wellnessSuite = await get(`/surveys/wellness-suite?lng=${language}`)

      setSurveys(wellnessSuite.surveys)
    } catch (err) {
      console.error('Failed to fetch surveys', err)
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  const handleStartSurvey = (wellKnownKey) => {
    relativeNavigate(`/surveys/${wellKnownKey}/questions`)
  }

  const handleNavigateToEvaluation = () => {
    relativeNavigate(`/surveys/wellness-suite/evaluation`)
  }

  const restartSurvey = async (wellKnownKey) => {
    if (wellKnownKey) {
      await deleteReq(`/surveys/${wellKnownKey}/responses`)
      handleStartSurvey(wellKnownKey)
    }
  }

  useEffect(() => {
    fetchSurveys()
  }, [])

  return (
    <>
      <Grid container className={classes.wellnessSuiteContainer}>
        <NavigateBack />
        <Grid item xs={12} className={classes.pageHeader}>
          <Heading extraClasses={[classes.headerTypography]}>
            {t('wellnessSuiteHeader')}
          </Heading>
        </Grid>

        {!loading && !error && (
          <>
            <Grid item xs={12} className={classes.profileNameContainer}>
              <Typography className={classes.profileName}>
                {t('greeting')} {profileContents && profileContents.name}!
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.intro}>
              <Typography
                className={classes.introText}
                dangerouslySetInnerHTML={{
                  __html: t('wellnessSuiteIntro'),
                }}
              ></Typography>
            </Grid>

            <Grid
              item
              container
              xs={12}
              className={classes.surveyCards}
              spacing={3}
            >
              {surveys.map((survey) => (
                <Grid item md={4} xs={12} key={survey.wellKnownKey}>
                  <Paper elevation={3} className={classes.surveyCard}>
                    <Typography className={classes.surveyName} gutterBottom>
                      {survey.title}
                    </Typography>
                    <Typography
                      className={classes.surveyDescription}
                      gutterBottom
                    >
                      {survey.intro || survey.description}
                    </Typography>

                    {survey.completed && (
                      <>
                        <Grid container>
                          <Grid item xs={12}>
                            <Box className={classes.completedBox}>
                              <CheckCircleOutlineIcon />
                              <Typography className={classes.completedText}>
                                {t('wellnessSuiteThankYou')}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <GradientButton
                              onClick={() => restartSurvey(survey.wellKnownKey)}
                            >
                              {t('wellnessSuiteRestartSurveyButton')}
                            </GradientButton>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {!survey.completed && (
                      <GradientButton
                        className={classes.startButton}
                        onClick={() => handleStartSurvey(survey.wellKnownKey)}
                      >
                        {t('wellnessSuiteStartSurveyButton')}
                      </GradientButton>
                    )}
                  </Paper>
                </Grid>
              ))}
            </Grid>

            <Grid
              container
              justifyContent="center"
              className={classes.evaluationContainer}
            >
              <Grid item xs={4}>
                <GradientButton
                  onClick={handleNavigateToEvaluation}
                  disabled={!allSurveysCompleted}
                >
                  {t('wellnessSuiteSurveysResults')}
                </GradientButton>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

WellnessSuite.displayName = 'WellnessSuite'
export { WellnessSuite }
export default WellnessSuite
