import React, { useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useStoreActions, useStoreState } from '../../store/store.hooks'
//import { useForm } from 'react-hook-form'
import { NavigateBack } from '../../components/Nav/NavigateBack'
import { Heading } from '../../components/Text'
import { GradientButton } from '../../components/Button'
import { get } from '../../services/request'
import { useRelativeNavigate } from '../../hooks/useRelativeNavigate'
import { MYMEDIO_URLS } from '../../constants'

const useStyles = makeStyles((theme) => ({
  autoWidthButton: {
    width: 'auto',
  },

  bookingContainer: {
    margin: '1rem 1rem',
  },

  bookingButtonContainer: {
    margin: '1rem',
  },

  evaluationDescription: {
    textAlign: 'center',
    '& a': {
      textDecoration: 'none',
      fontWeight: 'bold',
      color: '#1E2864',
    },
  },

  evaluationHeader: {
    fontWeight: 'bold',
    color: '#1E2864',
    marginBottom: '1rem',
  },

  explanationPaper: {
    padding: '1rem',
    height: '100%',
  },

  important: {
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '1rem',
  },

  intro: {
    padding: '1rem 2rem',
    textAlign: 'justify',
  },

  introText: {
    fontSize: '1.1rem',
    '& a': {
      textDecoration: 'none',
      color: '#1E2864',
      fontWeight: 600,
    },
  },

  missingEvaluation: {
    fontWeight: 'bold',
    color: '#1E2864',
  },

  page: {
    paddingTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '15px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },

  pageHeader: {
    pageHeader: {
      marginBottom: theme.spacing(3),
    },
  },

  progressContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
  },

  root: {},

  scheduleContainer: {
    marginTop: '2rem',
  },

  scoreBox: {
    padding: '1rem',
    backgroundColor: 'rgba(30, 40, 100, 0.05)',
    border: '1px solid rgba(30, 40, 100, 0.1)',
    borderRadius: '0.5rem',
    boxShadow: '0 4px 8px 0 rgba(30, 40, 100, 0.4)',
  },

  scorePaper: {
    padding: '1rem',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    height: '100%',

    [theme.breakpoints.down('md')]: {
      padding: '0.2rem',
    },
  },

  scoreText: {
    color: '#1E2864',
    fontWeight: 'bold',
    marginBottom: '1rem',
  },

  startSurveyButton: {
    marginTop: '1rem',
  },

  surveyItem: {
    padding: '0 1rem',
  },

  surveysContainer: {
    margin: '1rem 0',
  },
}))

const WellnessSuiteEvaluation = (
  {
    /* props */
  }
) => {
  const classes = useStyles()
  const {
    t,
    i18n: { language },
  } = useTranslation('survey')
  const { relativeNavigate } = useRelativeNavigate()
  const [surveys, setSurveys] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const isInternational = language !== 'hu'

  const { fetchSettings } = useStoreActions((actions) => actions.settings)
  const { settingsByKey } = useStoreState((state) => state.settings)

  const fetchSurveys = async () => {
    try {
      setLoading(true)
      const wellnessSuite = await get(`/surveys/wellness-suite?lng=${language}`)

      const { surveys: allSurveys } = wellnessSuite
      const surveysToShow = allSurveys.filter((s) => s.includeInEvaluation)

      setSurveys(surveysToShow)
    } catch (err) {
      console.error('Failed to fetch surveys', err)
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  const navigateInNewTab = (url) => {
    if (!url) return

    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const handleNavigateToStartPage = () => {
    relativeNavigate('/services')
  }

  const handleNavigateToSurvey = (surveyKey) => {
    relativeNavigate(`/surveys/${surveyKey}/questions`)
  }

  const navigateToMyMedioUrl = (mymedioUrl) => {
    const url = settingsByKey[mymedioUrl]

    if (!url) {
      console.error(`Tried to navigate to an unknown MyMedio URL`, mymedioUrl)
    }

    navigateInNewTab(url)
  }

  const navigateToOnlineConsultation = () => {
    if (isInternational) {
      navigateToMyMedioUrl(MYMEDIO_URLS.THERAPIST_INTL)
    } else {
      navigateToMyMedioUrl(MYMEDIO_URLS.THERAPIST)
    }
  }

  useEffect(() => {
    fetchSurveys()
  }, [])

  useEffect(() => {
    fetchSettings()
  }, [fetchSettings])

  return (
    <>
      <Grid container className={classes.page}>
        <NavigateBack />
        <Grid item xs={12} className={classes.pageHeader}>
          <Heading>{t('corporatePsychoDiagResultsHeader')}</Heading>
        </Grid>

        <Grid item xs={12} className={classes.intro}>
          <Typography
            className={classes.introText}
            dangerouslySetInnerHTML={{
              __html: t('wellnessSuiteEvalIntro'),
            }}
          />
        </Grid>

        {loading && (
          <div className={classes.progressContainer}>
            <CircularProgress />
          </div>
        )}

        {!loading && (
          <Grid item xs className={classes.surveysContainer}>
            {surveys.map((survey) => (
              <Grid
                container
                spacing={3}
                key={survey.id}
                className={classes.surveyItem}
              >
                <Grid item xs={4}>
                  <Paper className={classes.scorePaper} elevation={3}>
                    <Typography className={classes.scoreText}>
                      {survey.title}
                    </Typography>
                    <Box className={classes.scoreBox}>
                      {t('corporatePsychoDiagResultsScore')}:{' '}
                      {`${survey.userScore || '-'} / ${survey.maxScore}`}
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={8}>
                  <Paper className={classes.explanationPaper} elevation={3}>
                    {!(survey.evaluation && survey.evaluation.text) && (
                      <>
                        <Typography className={classes.missingEvaluation}>
                          {t('corporatePsychoDiagMissingEvaluation')}
                        </Typography>

                        <GradientButton
                          onClick={() =>
                            handleNavigateToSurvey(survey.wellKnownKey)
                          }
                          className={[
                            classes.autoWidthButton,
                            classes.startSurveyButton,
                          ].join(' ')}
                        >
                          {t('wellnessSuiteStartSurveyButton')}
                        </GradientButton>
                      </>
                    )}

                    {survey.evaluation && survey.evaluation.text && (
                      <Typography className={classes.evaluationHeader}>
                        {survey.evaluation.text}
                      </Typography>
                    )}

                    {survey.evaluation && survey.evaluation.description && (
                      <Typography
                        className={classes.evaluationDescription}
                        dangerouslySetInnerHTML={{
                          __html: survey.evaluation.description,
                        }}
                      ></Typography>
                    )}
                  </Paper>
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}

        <Grid container className={classes.scheduleContainer}>
          <Grid item xs>
            <Typography className={classes.important}>
              {t('wellnessSuiteInfo')}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="center"
          xs={12}
          className={classes.bookingContainer}
        >
          <Grid item xs={12} md={4} className={classes.bookingButtonContainer}>
            <GradientButton
              onClick={handleNavigateToStartPage}
              className={classes.autoWidthButton}
            >
              {t('wellnessSuiteBackToStartPageButton')}
            </GradientButton>
          </Grid>

          {!isInternational && (
            <Grid
              item
              xs={12}
              md={4}
              className={classes.bookingButtonContainer}
            >
              <GradientButton
                onClick={navigateToOnlineConsultation}
                className={classes.autoWidthButton}
              >
                {t('wellnessSuiteScheduleOfflineButton')}
              </GradientButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  )
}

WellnessSuiteEvaluation.displayName = 'WellnessSuiteEvaluation'
export { WellnessSuiteEvaluation }
export default WellnessSuiteEvaluation
