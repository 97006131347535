/*
    Copyright © DokiApp, 2022
*/

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Backdrop,
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { NavigateBack } from '../../components/Nav/NavigateBack'
import { GradientButton } from '../../components/Button'
import { useTranslation } from 'react-i18next'
import { get, post } from '../../services/request'
import { useRelativeNavigate } from '../../hooks/useRelativeNavigate'
import { PagedSurveyQuestionList } from './PagedSurveyQuestionList'
import _ from 'lodash'
import { useStoreActions } from '../../store/store.hooks'
import { SurveyLegend } from './SurveyLegend'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  buttonContainer: {
    margin: '0 2rem',
  },
  centered: {
    textAlign: 'center',
  },
  heading: {
    textAlign: 'center',
    width: '100%',
    margin: '1rem',
    fontSize: '2rem',
  },
  nextButton: {
    width: '90%',
    boxShadow: ' 0 10px 20px 0 rgba(100,115,175,0.4)',
  },
  page: {
    backgroundColor: '#fff',
    borderRadius: '15px',
  },
  pageHeader: {
    marginBottom: theme.spacing(3),
  },
  previousButton: {
    width: '90%',
    boxShadow: ' 0 10px 20px 0 rgba(100,115,175,0.4)',

    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginBottom: 30,
    },
  },
  progress: {
    margin: '2rem',
    textAlign: 'center',
  },
  questionContainer: {
    margin: '0 2rem 2rem',
    textAlign: 'justify',
  },
}))

const SurveyQuestions = ({ surveyId }) => {
  const {
    i18n: { language },
    t,
  } = useTranslation('survey')
  const classes = useStyles()
  const { relativeNavigate } = useRelativeNavigate()
  const { setNotification } = useStoreActions((actions) => actions.layout)

  const [survey, setSurvey] = useState()
  const [error, setError] = useState()
  const [pageIndex, setPageIndex] = useState(0)
  const [totalPageCount, setTotalPageCount] = useState(0)
  const [pages, setPages] = useState()
  const [currentPage, setCurrentPage] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  const handlePreviousClick = () => {
    if (pageIndex > 0) {
      setPageIndex((prev) => prev - 1)
      scrollToTop()
    }
  }

  const handleNextClick = () => {
    // Validate that all questions have been answered
    const unansweredQuestions = currentPage.filter(
      // open ended questions are not required to have a recorded answer
      (q) => q.type !== 'open-ended' && !q.recordedAnswer
    )

    const hasMissingAnswers =
      Array.isArray(unansweredQuestions) && unansweredQuestions.length > 0

    if (hasMissingAnswers) {
      setNotification({
        isOpen: true,
        type: 'warning',
        title: t('missingAnswers'),
      })
    } else {
      if (pageIndex + 1 < totalPageCount) {
        setPageIndex((prev) => prev + 1)
        scrollToTop()
      } else {
        if (survey.category === 'corporate-diagnostics') {
          relativeNavigate(`/surveys/${survey.category}`)
        } else {
          const redirectPathSegment =
            survey && (survey.category || survey.wellKnownKey)
          relativeNavigate(`/surveys/${redirectPathSegment}/evaluation`)
        }
      }
    }
  }

  const handleQuestionAnswered = async (response) => {
    try {
      const { answer, question } = response

      // Save the recorded answer on the original question array
      const newPages = pages.map((p) =>
        p.map((q) => {
          if (q.id === question.id) {
            return { ...q, recordedAnswer: answer }
          } else {
            return q
          }
        })
      )

      setPages(newPages)

      const payload = {
        ...response,
        survey,
      }
      const serverResponse = await post(
        `/surveys/${surveyId}/responses`,
        payload
      )
      console.log('Survey response saved', payload, serverResponse)
    } catch (err) {
      console.error('Failed to save survey answer', err)
    }
  }

  useEffect(() => {
    const fetchSurvey = async () => {
      try {
        setIsLoading(true)
        setError(null)
        const survey = await get(`/surveys/${surveyId}?lng=${language}`)
        setSurvey(survey)
      } catch (err) {
        setError(err)
      } finally {
        setIsLoading(false)
      }
    }

    fetchSurvey()
  }, [])

  useEffect(() => {
    if (
      survey &&
      Array.isArray(survey.questions) &&
      survey.questions.length > 0
    ) {
      const allPages = [[]]
      let currentPageIndex = 0

      for (const question of survey.questions) {
        if (!allPages[currentPageIndex]) {
          allPages[currentPageIndex] = []
        }
        if (question && question.type !== 'divider') {
          allPages[currentPageIndex].push({
            ...question,
            recordedAnswer: null,
            visible: true,
          })
        } else {
          currentPageIndex++
        }
      }

      setTotalPageCount(allPages.length)
      setPages(allPages)
    }
  }, [survey])

  useEffect(() => {
    if (pages && Array.isArray(pages) && pages.length > pageIndex) {
      const currentPage = pages[pageIndex]
      setCurrentPage(currentPage)
    }
  }, [pageIndex])

  useEffect(() => {
    if (Array.isArray(pages) && pages.length > 0) {
      const newPage = pages[pageIndex].filter((q) => {
        const { dependsOnQuestionId, dependsOnAnswerId } = q
        if (dependsOnQuestionId > 0 && dependsOnAnswerId > 0) {
          const matchingQuestion = pages[pageIndex].find(
            (x) => x.id === dependsOnQuestionId
          )

          if (matchingQuestion) {
            const { recordedAnswer } = matchingQuestion
            if (!recordedAnswer) {
              return true
            } else {
              const hasDependingAnswer =
                matchingQuestion &&
                matchingQuestion.recordedAnswer &&
                matchingQuestion.recordedAnswer.id === dependsOnAnswerId

              return hasDependingAnswer
            }
          } else {
            return true
          }
        }

        return true
      })

      setCurrentPage(newPage)
    }
  }, [pages, pageIndex])

  if (error) {
    return error
  }

  if (isLoading) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  return (
    <Grid container className={classes.page}>
      <NavigateBack />

      <Typography variant="h3" className={classes.heading}>
        {survey && survey.title}
      </Typography>

      <SurveyLegend survey={survey} />

      <PagedSurveyQuestionList
        questions={currentPage}
        onAnswered={handleQuestionAnswered}
        category={survey && survey.category}
      />

      <Grid
        container
        justifyContent="center"
        spacing={2}
        className={classes.buttonContainer}
      >
        <Grid item xs={6} className={classes.centered}>
          <GradientButton
            className={classes.previousButton}
            onClick={handlePreviousClick}
            disabled={pageIndex - 1 < 0}
          >
            {t('previousQuestion')}
          </GradientButton>
        </Grid>
        <Grid item xs={6} className={classes.centered}>
          <GradientButton
            className={classes.nextButton}
            onClick={handleNextClick}
          >
            {pageIndex + 1 < totalPageCount
              ? t('nextQuestion')
              : t('completeSurvey')}
          </GradientButton>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.progress}>
        <Typography>
          {pageIndex + 1} / {totalPageCount}
        </Typography>
      </Grid>
    </Grid>
  )
}

SurveyQuestions.displayName = 'SurveyQuestions'

SurveyQuestions.propTypes = {}

SurveyQuestions.defaultProps = {}

export default SurveyQuestions
export { SurveyQuestions }
