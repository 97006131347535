const MYMEDIO_URLS = Object.freeze({
  BMW_ONLINE_THERAPIST: 'mymedio-bmw-online-therapist',
  BMW_ONLINE_THERAPIST_INTL: 'mymedio-bmw-online-therapist-intl',
  BMW_OFFLINE_THERAPIST: 'mymedio-bmw-offline-therapist',
  BMW_OFFLINE_THERAPIST_INTL: 'mymedio-bmw-offline-therapist-intl',
  CHILD_THERAPIST: 'mymedio-child-therapist',
  CHILD_THERAPIST_INTL: 'mymedio-child-therapist-intl',
  DIETITIAN: 'mymedio-dietitian',
  DIETITIAN_INTL: 'mymedio-dietitian-intl',
  LACTATION_CONSULTANT: 'mymedio-lactation-consultant',
  LACTATION_CONSULTANT_INTL: 'mymedio-lactation-consultant-intl',
  MENTAL_ACADEMY: 'mental-academy',
  MENTAL_ACADEMY_INTL: 'mental-academy-intl',
  MYMEDIO_APPOINTMENT_BOOKING: 'mymedio-appointment-booking',
  PHARMACIST: 'mymedio-pharmacist',
  PHARMACIST_INTL: 'mymedio-pharmacist-intl',
  PHYSICAL_THERAPY: 'mymedio-physical-therapy',
  PHYSICAL_THERAPY_INTL: 'mymedio-physical-therapy-intl',
  THERAPIST: 'mymedio-therapist',
  THERAPIST_INTL: 'mymedio-therapist-intl',
})

export { MYMEDIO_URLS }
